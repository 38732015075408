#feature-section-3-link-to {
  position: relative;
  top: 102px;
}

#feature-section-3 {
  margin-top: 202px;
}

#body-line-4 {
  height: 1060px;
  width: 3px;
  top: 185px;
  left: 180px;
}

@media (max-width: 1140px) {
  #feature-section-3-link-to {
    top: -50px;
  }

  #feature-section-3 {
    margin-top: 50px;
    padding: 0 20px;
  }

  #body-line-4 {
    display: none;
  }
}

/*# sourceMappingURL=index.1dbd89eb.css.map */
