@import "constants";

#feature-section-3-link-to {
  position: relative;
  top: 102px;
}

#feature-section-3 {
  margin-top: 202px;
}

#body-line-4 {
  left: $body-line-left;
  height: 1060px;
  width: $body-line-size;
  top: 185px;
}

@media (max-width: $mobile-screen-width) {
  #feature-section-3-link-to {
    top: -50px;
  }

  #feature-section-3 {
    margin-top: 50px;
    padding: 0 $mobile-content-horizontal-padding;
  }

  #body-line-4 {
    display: none;
  }
}
